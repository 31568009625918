import { useState } from 'react';
import { View, Image, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { Entypo } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _size from 'lodash/size';

import { IMAGES } from '../../assets/images';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { COLORS } from '../../constants/colors';
import { PICKER_INPUT_STYLES, STATUS, USER_PROFILE_OPTIONS } from '../../constants/common';
import { getOperationalCities } from '../../selectors/common';
import { checkReferralCodeValidity, getOperationalCitiesOptions, validateSignupDetails } from '../../utils/common';
import Button from '../../components/Button';

const DEFAULT_PROFESSION = { label: 'I’m a...', value: null };
const DEFAULT_CITY = { label: 'Select your city', value: null };

// {
//   displayName, phoneNumber, email, companyName, profession;
// }
const SignupDetails = ({ cities, email: userEnteredEmail, userName, onSignupPress }) => {
  const toast = useToast();
  const [displayName, setDisplayName] = useState(userName || '');
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [email, setEmail] = useState(userEnteredEmail);
  const [companyName, setCompanyName] = useState('');
  const [referral, setReferral] = useState('');
  const [profession, setUserProfession] = useState('');
  const [userCity, setUserCity] = useState('');
  const [signingupUser, setSigningupUser] = useState(false);

  const onClick = async () => {
    toast.hideAll();
    const validationDetails = await validateSignupDetails({
      displayName,
      phoneNumber,
      email: userEnteredEmail,
      companyName,
      profession,
      userCity,
      referral,
    });
    // console.log({ validationDetails });
    if (validationDetails.status === STATUS.FAILED) {
      toast.show(_get(validationDetails, 'msg'));
      return;
    }
    const userDetails = {
      displayName,
      phoneNumber,
      email: userEnteredEmail,
      companyName,
      referral,
      profession,
      userCity,
    };
    if (_size(referral) > 0) {
      const { status, organizationId, msg } = await checkReferralCodeValidity(referral, userEnteredEmail);
      if (status === STATUS.SUCCESS) {
        userDetails.referral = referral;
        userDetails.organizationId = organizationId;
      } else if (status === STATUS.FAILED) {
        toast.show(msg);
        return;
      }
    }
    setSigningupUser(true);
    await onSignupPress(userDetails);
    setSigningupUser(false);
  };

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 100 }}>
        {/* <Image source={IMAGES.ONBOARDING} style={styles.bannerImage} /> */}
        <View style={styles.innerContainer}>
          <Text size={2} variant="bold" style={styles.baseMarginBottom}>
            Sign up for GoFloaters
          </Text>
          <TextInput
            value={displayName}
            onChangeText={setDisplayName}
            placeholder="Full name"
            containerStyle={styles.baseMarginBottom}
          />
          <View style={[styles.phoneInputStyle, styles.baseMarginBottom]}>
            <Text size={3} style={{ color: COLORS.TEXT_COLOR_SECONDARY }}>
              +91
            </Text>
            <TextInput
              value={phoneNumber}
              onChangeText={setPhoneNumber}
              containerStyle={{ borderWidth: 0, borderColor: 'red', flex: 1 }}
              maxLength={10}
            />
          </View>
          <TextInput
            value={userEnteredEmail}
            // onChangeText={setEmail}
            editable={false}
            placeholder="Email ID"
            containerStyle={styles.baseMarginBottom}
          />

          <TextInput
            value={companyName}
            onChangeText={setCompanyName}
            placeholder="Company"
            containerStyle={styles.baseMarginBottom}
          />
          <TextInput
            value={referral}
            onChangeText={setReferral}
            placeholder="Referral Code"
            containerStyle={styles.baseMarginBottom}
          />
          {/* <TextInput placeholder="I’m a..." containerStyle={styles.baseMarginBottom} /> */}
          <RNPickerSelect
            placeholder={DEFAULT_PROFESSION}
            style={PICKER_INPUT_STYLES}
            onValueChange={setUserProfession}
            value={profession}
            // Icon={() => <Entypo name="chevron-down" size={16} color={COLORS.TEXT_COLOR_SECONDARY} />}
            items={USER_PROFILE_OPTIONS}
            useNativeAndroidPickerStyle={false}
          />
          <RNPickerSelect
            placeholder={DEFAULT_CITY}
            style={PICKER_INPUT_STYLES}
            onValueChange={setUserCity}
            value={userCity}
            Icon={() => <Entypo name="chevron-down" size={16} color={COLORS.TEXT_COLOR_SECONDARY} />}
            items={getOperationalCitiesOptions(cities)}
            useNativeAndroidPickerStyle={false}
          />
        </View>
      </ScrollView>
      <View style={{ width: '100%', padding: 16, backgroundColor: COLORS.WHITE, position: 'absolute', bottom: 0 }}>
        <Button textProps={{ variant: 'bold', size: 3 }} onPress={onClick} loading={signingupUser}>
          Sign up
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  innerContainer: {
    paddingHorizontal: 20,
  },
  bannerImage: {
    height: 150,
    marginBottom: 32,
  },
  baseMarginBottom: {
    marginBottom: 32,
  },
  phoneInputStyle: {
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
  },
});

const mapStateToProps = state => ({
  cities: getOperationalCities(state.metadata.data),
});

export default connect(mapStateToProps)(SignupDetails);
