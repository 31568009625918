import _get from 'lodash/get';
import _split from 'lodash/split';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
export const getShowOrgLevelCredits = state => _get(state, 'user.organization.config.showOrgLevelCredits');
export const getShowWhereIsOrg = state => _get(state, 'user.organization.config.showWhereIsOrg');
export const getShowMemberNames = state => _get(state, 'user.organization.config.showMemberNames');
export const getOrgCredits = state => _get(state, 'user.organization.orgCredits');
export const getOrganizationId = state => _get(state, 'user.organization.organizationId');
// export const getShowMemberNames = state => _get(state, 'user.organization.config.showMemberNames')
export const getShowRates = state => _get(state, 'user.organization.config.showRates');
export const getBlockAppUsage = state => _get(state, 'user.organization.config.blockAppUsage');
export const getBlockWebUsage = state => _get(state, 'user.organization.config.blockWebUsage');
export const getIsB2CUser = state => {
          // return true;
          const b2cEmailDomains = ["gmail.com","yahoo.com","hotmail.com","rediffmail.com"];
          const [email, domain] = _split(_get(state, 'user.user.email'), '@');
          // console.log("getIsB2CUser",_includes(b2cEmailDomains, domain));
          return _includes(b2cEmailDomains, domain);
};
export const getCanUseSOSFeature = state => !_isEmpty(_get(state, 'user.organization.config.sosContacts'));
export const getSOSHelpline = state => {
  // console.log({ organisation: _get(state, 'user.organization') });
  return _get(state, 'user.organization.config.sosHelpLine');
};
