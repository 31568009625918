import { IMAGES } from '../assets/images';
import { SVGS } from '../assets/svgs';
import { COLORS } from './colors';

export const BOOKING_STATUS = {
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
};

export const BOOKING_STATUS_LABEL = {
  [BOOKING_STATUS.CONFIRMED]: 'Confirmed',
  [BOOKING_STATUS.REJECTED]: 'Rejected',
};

export const BOOKING_STATUS_LABEL_COLOR = {
  [BOOKING_STATUS.CONFIRMED]: COLORS.STATUS_COLORS.SUCCESS,
  [BOOKING_STATUS.REJECTED]: COLORS.STATUS_COLORS.FAILURE,
};

export const SPACING = 16;
export const MAXDEVICEWIDTH = 412;
export const PROFILE_ICON_SIZE = 20;
export const BOOKING_STAGE_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
};

export const NOTIFICATION_TYPES = {
  BOOKING_SUCCESS: 'BOOKING_SUCCESS',
  BOOKING_FAILED: 'BOOKING_FAILED',
  VISIT_COMPLETED: 'VISIT_COMPLETED',
};

export const TOP_CITIES = [
  'New Delhi',
  'Gurugram',
  'Noida',
  'Mumbai',
  'Pune',
  'Hyderabad',
  'Bengaluru',
  'Chennai',
  'Kolkata',
];
// New Delhi Gurugram Noida Mumbai Pune Hyderabad Bengaluru Chennai Kolkata
export const QUICK_FILTERS = [
  { displayName: 'HQ Spaces', value: 'HQ_SPACES' },
  { displayName: 'WorkFlexi', value: 'WORKFLEXI' },
  { displayName: 'Metro', value: 'METRO' },
  { displayName: 'Parking', value: 'PARKING' },
];

export const USER_PROFILE_OPTIONS = [
  { label: 'Freelancer', value: 'Freelancer' },
  { label: 'Student', value: 'Student' },
  { label: 'Independent professional', value: 'Independent professional' },
  { label: 'Startup Owner/Cofounder', value: 'Startup Owner/Cofounder' },
  { label: 'Startup employee', value: 'Startup employee' },
  { label: 'Small-Medium Business Owner', value: 'Small-Medium Business Owner' },
  { label: 'Large Enterprise Employee', value: 'Large Enterprise Employee' },
  { label: 'Other', value: 'Other' },
];

export const SPACE_TYPE_VALUES = {
  OFFICE_SPACES: 'officeSpace',
  MEETING_SPACE: 'meetingSpace',
};

export const SPACE_TYPES = [
  {
    label: 'Desks',
    value: SPACE_TYPE_VALUES.OFFICE_SPACES,
  },
  { label: 'Meeting Rooms', value: SPACE_TYPE_VALUES.MEETING_SPACE },
];

export const BOOKING_TYPE_VALUES = {
  ACTIVE: 'ACTIVE',
  PAST: 'PAST',
};

export const BOOKING_TYPES = [
  {
    label: 'Active',
    value: BOOKING_TYPE_VALUES.ACTIVE,
  },
  { label: 'Past Bookings', value: BOOKING_TYPE_VALUES.PAST },
];

export const STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const PICKER_INPUT_STYLES = {
  inputIOSContainer: {
    height: 45,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    justifyContent: 'center',
    padding: 12,
    paddingRight: 30,
    marginBottom: 20,
  },
  placeholder: {
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
  textInputProps: {
    color: COLORS.TEXT_COLOR_PRIMARY,
  },
  chevronContainer: {
    right: 16,
    paddingRight: 16,
    marginRight: 16,
  },
  chevron: {
    right: 16,
    paddingRight: 16,
    marginRight: 16,
  },
  inputAndroidContainer: {
    height: 45,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    justifyContent: 'center',
    padding: 12,
    paddingRight: 30,
    marginBottom: 20,
  },
  inputWeb: {
    height: 45,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    justifyContent: 'center',
    padding: 12,
    paddingRight: 30,
    color: COLORS.TEXT_COLOR_SECONDARY,
    right: 16,
    marginBottom: 20,
  },
  iconContainer: {
    display: 'none',
  },
};

export const SPACE_BOOKING_TYPES = {
  HOUR: 'Hour',
  DAY: 'Day',
};

export const SIGNIN_VIEWS = {
  SIGN_IN: 'SIGN_IN',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SIGNUP_DETAILS: 'SIGNUP_DETAILS',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};

export const MAP_DATES = {
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  DAY_AFTER: 'DAY_AFTER',
};

export const MAP_DATES_OPTIONS = [
  {
    label: 'Today',
    value: MAP_DATES.TODAY,
  },
  {
    label: 'Tomorrow',
    value: MAP_DATES.TOMORROW,
  },
  {
    label: 'Day After',
    value: MAP_DATES.DAY_AFTER,
  },
];

export const NUMBERS = ['First', 'Second', 'Third', 'Fourth', 'Five'];
export const MAX_ALLOWED_BOOKINGS = 5;

export const DAYS = {
  MONDAY: {
    label: 'Monday',
    value: 'monday',
  },
  TUESDAY: {
    label: 'Tuesday',
    value: 'tuesday',
  },
  WEDNESDAY: {
    label: 'Wednesday',
    value: 'wednesday',
  },
  THURSDAY: {
    label: 'Thursday',
    value: 'thursday',
  },
  FRIDAY: {
    label: 'Friday',
    value: 'friday',
  },
  SATURDAY: {
    label: 'Saturday',
    value: 'saturday',
  },
  SUNDAY: {
    label: 'Sunday',
    value: 'sunday',
  },
};

export const WEEKDAYS = [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY];
export const WEEKEND = [DAYS.SATURDAY, DAYS.SUNDAY];

export const FILTER_TYPES = {
  SELECT: 'SELECT',
  POPOVER: 'POPOVER',
};

export const SEARCH_TYPE = {
  GOFLOATERS: 'GOFLOATERS',
  GOOGLE: 'location',
  MICRO_MARKET: 'microMarket',
};

export const AMENITIES_VS_IMAGE = {
  'Hi Speed WiFi': IMAGES.HI_SPEED_WIFI,
  AC: IMAGES.AC,
  'Rest Rooms': IMAGES.RESTROOM,
  'Power Backup': IMAGES.POWER_BACKUP,
  'WiFi Backup': IMAGES.WIFI_BACKUP,
  'Outside Food Allowed': IMAGES.OUTSIDE_FOOD_ALLOWED,
  'Coffee/Tea': IMAGES.TEA_COFFEE,
  Printer: IMAGES.PRINTER,
  Copier: IMAGES.COPIER,
  'Disinfected Daily': IMAGES.DISINFECTED_DAILY,
  'Paid Parking': IMAGES.PAID_PARKING,
  'Distance >3 Feet': IMAGES.DISTANCE_3FT,
  'Office Stationery': IMAGES.OFFICE_STATIONARY,
  'Smoking Zone': IMAGES.SMOKING_AREA,
  'Metro Nearby': IMAGES.METRO,
  'Hand Sanitizers': IMAGES.HAND_SANITIZER,
  Television: IMAGES.TV,
  Projector: IMAGES.PROJECTOR,
  Pantry: IMAGES.PANTRY,
  'Audio Equipment': IMAGES.AUDIO_EQUIPMENT,
  'Video Conference': IMAGES.VIDEO_CONFERENCE,
  Whiteboard: IMAGES.WHITEBOARD,
  'Face Masks Available': IMAGES.MASK_AVAILABLE,
  'Free Parking': IMAGES.PARKING,
  'Phone Booth': IMAGES.TELEPHONE_BOOTH,
  'Discussion Booth': IMAGES.DISCUSSION_BOOTH,
  'Food Court': IMAGES.FOOD_COURT,
  'Lounge Area': IMAGES.LOUNGE_AREA,
  'Office Stationary': IMAGES.STATIONERY,
  'Recreation & Games Area': IMAGES.RECREATION,
  'Meeting Rooms': IMAGES.MEETING_ROOM,
};

export const SIGN_UP_METHODS = {
  EMAIL: 'EMAIL',
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
};

export const IMAGES_VS_SEARCH_TYPE = {
  [SEARCH_TYPE.GOFLOATERS]: SVGS.GOFLOATERS_SPACE,
  [SEARCH_TYPE.MICRO_MARKET]: SVGS.MICRO_MARKET,
  [SEARCH_TYPE.GOOGLE]: SVGS.GOOGLE_SEARCH,
};

export const SPACE_SORT_VALUES = {
  POPULAR: 'POPULAR',
  RATING: 'RATING',
  PRICE_LOW: 'PRICE_LOW',
  PRICE_HIGH: 'PRICE_HIGH',
  DISTANCE: 'DISTANCE',
};

export const SPACE_SORT_OPTIONS = [
  {
    title: 'Distance: ',
    subtitle: 'Lowest',
    value: SPACE_SORT_VALUES.DISTANCE,
  },
  {
    title: 'Popular: ',
    subtitle: 'Frequently booked',
    value: SPACE_SORT_VALUES.POPULAR,
  },
  {
    title: 'User Rating: ',
    subtitle: 'Highest first',
    value: SPACE_SORT_VALUES.RATING,
  },
];

export const SPACE_SORT_OPTIONS_PRICING = [
  {
    title: 'Price: ',
    subtitle: 'Lowest first',
    value: SPACE_SORT_VALUES.PRICE_LOW,
  },
  {
    title: 'Price: ',
    subtitle: 'Highest first',
    value: SPACE_SORT_VALUES.PRICE_HIGH,
  },
];

export const CALENDAR_WEB_VALUES = {
  GOOGLE: 'GOOGLE',
  OUTLOOK: 'OUTLOOK',
};

export const CALENDAR_WEB_OPTIONS = [
  {
    title: 'Google',
    value: CALENDAR_WEB_VALUES.GOOGLE,
    icon: IMAGES.GOOGLE_CALENDAR,
  },
  {
    title: 'Outlook',
    value: CALENDAR_WEB_VALUES.OUTLOOK,
    icon: IMAGES.OUTLOOK,
  },
];

export const APP_VERSION = '1.1.7-CP3';
export const SHOW_WHATS_NEW = true;

export const SPACE_APPROVE_STATE = {
  APPROVE: 'approve',
  DISAPPROVE: 'disapprove',
};
